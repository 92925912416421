import assert from 'assert'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
    Grid,
    FormControl as _FormControl,
    InputLabel,
    Select,
    MenuItem,
    useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { Link, navigate, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useLocalStorage } from 'react-use'
import { Layout } from '../components/Layout'
import { OptionImage } from '../components/OptionImage'
import { RingBaseSelect } from '../components/RingBaseSelect'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import { metals, widths, diamonds, textures, mills } from '../types/options'
import { makeImageUrl, makeArrangeUrl } from '../utils'
import type { RingCustomizeQuery } from '../../types/graphql-types'
import type { CustomizeRing, RingFigure } from '../types/ring'
import type { SelectChangeEvent } from '@mui/material'
import type { PageProps, HeadProps } from 'gatsby'

type PageContext = {
    rings: Array<CustomizeRing>
    figure: RingFigure
    name: string
    metaTitle: string
    metaDescription: string
}

export const Head: React.FC<HeadProps<RingCustomizeQuery>> = ({ data }) => {
    const pageContext = convertToPageCtx(data)
    return (
        <SEO
            title={pageContext.metaTitle}
            description={pageContext.metaDescription}
            page={pageContext.figure}
        />
    )
}

const SECTIONS = [
    {
        title: 'デザインから始まるフルオーダー',
        description:
            '簡単な手書きの画像を送るだけで、リング完成画像と見積りがご来店前でも簡単にご覧頂けます。“おふたり”がデザイナー、リング完成画像製作０円。CADデザイナーによるデザイン画像製作依頼も承っております。',
        path: 'rental',
        image: (
            <StaticImage
                src="../images/fullorder/catalog/design-4.jpg"
                alt=""
            />
        ),
    },
    {
        title: 'アレンジのお見積もり',
        description:
            'カスタマイズのリングにオリジナルのアレンジを追加でご検討の方もお気軽にご相談下さい、お見積もりと併せ確認用画像をご案内させて頂きます。',
        path: 'semiorder/arrange',
        image: <StaticImage src="../images/customize/arrange.jpg" alt="" />,
    },
    {
        title: '内側の刻印、誕生石',
        description:
            'リングの内側に刻印を無料でご利用頂けます。数字、英字(ブロック体大文字)、一部記号(＆、♡)フォントや字体のアレンジもお気軽にご相談ください。誕生石の石留めは1ピース2,000円でご利用頂けます。',
        image: <StaticImage src="../images/customize/kokuin.jpg" alt="" />,
    },
]
const CustomizePage: React.FC<PageProps<RingCustomizeQuery>> = ({ data }) => {
    const pageContext = convertToPageCtx(data)
    const rings = useMemo(() => {
        return pageContext.rings
    }, [pageContext.rings])

    if (rings.length === 0) {
        return null
    }

    return <CustomizePageCore {...pageContext} />
}

const customizableTypes = [
    'metal',
    'width',
    'diamond',
    'texture',
    'mill',
] as const
type CustomizableType = typeof customizableTypes[number]
const equalExcludeType = (
    type: CustomizableType,
    value: string,
    ring1: CustomizeRing,
    ring2: CustomizeRing
) => {
    if (ring1[type] !== value) return false
    return customizableTypes
        .filter((t) => t !== type)
        .every((t) => ring1[t] === ring2[t])
}

const CustomizePageCore: React.FC<PageContext> = ({ rings, figure, name }) => {
    const muiTheme = useTheme()
    const [metal, setMeatal] = useLocalStorage(
        'metal_' + figure,
        rings[0].metal
    )
    const [width, setWidth] = useLocalStorage('width_' + figure, rings[0].width)
    const [diamond, setDiamond] = useLocalStorage(
        'diamond_' + figure,
        rings[0].diamond
    )
    const [texture, setTexture] = useLocalStorage(
        'texture_' + figure,
        rings[0].texture
    )
    const [mill, setMill] = useLocalStorage('mill_' + figure, rings[0].mill)
    const [ring, setRing] = useState<CustomizeRing>(rings[0])

    const metalOptions = useMemo(() => {
        return metals.filter((m) =>
            rings.some((r) => equalExcludeType('metal', m.value, r, ring))
        )
    }, [rings, ring])
    const widthOptions = useMemo(() => {
        return widths.filter((w) =>
            rings.some((r) => equalExcludeType('width', w.value, r, ring))
        )
    }, [rings, ring])
    const diamondOptions = useMemo(() => {
        return diamonds.filter((d) =>
            rings.some((r) => equalExcludeType('diamond', d.value, r, ring))
        )
    }, [rings, ring])
    const textureOptions = useMemo(() => {
        return textures.filter((t) =>
            rings.some((r) => equalExcludeType('texture', t.value, r, ring))
        )
    }, [rings, ring])
    const millOptions = useMemo(() => {
        return mills.filter((m) =>
            rings.some((r) => equalExcludeType('mill', m.value, r, ring))
        )
    }, [rings, ring])

    const handleSelectArrange = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            navigate(makeArrangeUrl(ring.model))
        },
        [ring.model]
    )

    useEffect(() => {
        const r = rings.find(
            (r) =>
                r.metal === metal &&
                r.width === width &&
                r.diamond === diamond &&
                r.texture === texture &&
                r.mill === mill
        )
        if (r === undefined) {
            setMeatal(rings[0].metal)
            setWidth(rings[0].width)
            setDiamond(rings[0].diamond)
            setTexture(rings[0].texture)
            setMill(rings[0].mill)
            setRing(rings[0])
            return
        }
        setRing(r)
    }, [metal, width, diamond, texture, mill, rings])

    const options = [
        {
            name: '地金の種類',
            label: 'metal-label',
            value: metal,
            options: metalOptions,
            onChange: (event: SelectChangeEvent<string>) =>
                setMeatal(event.target.value),
        },
        {
            name: '幅',
            label: 'width-label',
            value: width,
            options: widthOptions,
            onChange: (event: SelectChangeEvent<string>) =>
                setWidth(event.target.value),
        },
        {
            name: 'ダイヤの数と留め方',
            label: 'diamond-label',
            value: diamond,
            options: diamondOptions,
            onChange: (event: SelectChangeEvent<string>) =>
                setDiamond(event.target.value),
        },
        {
            name: '表面の質感',
            label: 'texture-label',
            value: texture,
            options: textureOptions,
            onChange: (event: SelectChangeEvent<string>) =>
                setTexture(event.target.value),
        },
        {
            name: 'ミル打ち',
            label: 'mill-label',
            value: mill,
            options: millOptions,
            onChange: (event: SelectChangeEvent<string>) =>
                setMill(event.target.value),
        },
    ]

    return (
        <Layout breadcrumbs={[{ name: name }]}>
            <Grid container spacing={0}>
                <Grid item sm={12} xs={12}>
                    <Title>
                        <Txt>リングをカスタマイズしてください。</Txt>
                    </Title>
                </Grid>
                <RingImageGrid item sm={6} xs={12} theme={muiTheme}>
                    <RingImageArea>
                        <Carousel autoPlay={false} height="300px">
                            {ring.images.map((image, index) => (
                                <RingImage
                                    src={makeImageUrl(image)}
                                    alt=""
                                    key={index}
                                />
                            ))}
                        </Carousel>
                    </RingImageArea>
                </RingImageGrid>
                <Grid item sm={6} xs={12}>
                    <CatchWrapper>
                        <CatchTxt1>理想のリングにカスタマイズ ▼</CatchTxt1>
                        <CatchTxt2>
                            オプションを選ぶと画像が替わります
                        </CatchTxt2>
                    </CatchWrapper>
                    <Grid container spacing={0}>
                        <Grid item sm={6} xs={6}>
                            <RingInfoWrapper>
                                <Row>
                                    <Label>型番</Label>
                                    <Txt>{ring.model}</Txt>
                                </Row>
                            </RingInfoWrapper>
                            <LinksWrapper>
                                <HelpLink to="/semiorder/arrange/">
                                    <Txt size="s">アレンジ事例一覧</Txt>
                                </HelpLink>
                                <HelpLink to="/trying-on/">
                                    <Txt size="s">ご注文の流れ</Txt>
                                </HelpLink>
                            </LinksWrapper>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <ControlWrapper>
                                {options.map((o) => (
                                    <FormControl
                                        key={o.label}
                                        disabled={o.options.length === 1}
                                    >
                                        <InputLabel id={o.label}>
                                            {o.name}
                                        </InputLabel>
                                        <Select
                                            variant="standard"
                                            labelId={o.label}
                                            label={o.name}
                                            value={o.value}
                                            onChange={o.onChange}
                                        >
                                            {o.options.map((oo) => (
                                                <MenuItem
                                                    value={oo.value}
                                                    key={oo.value}
                                                >
                                                    <OptionImage
                                                        filename={
                                                            figure +
                                                            '/' +
                                                            oo.value +
                                                            '.jpg'
                                                        }
                                                    />
                                                    {oo.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ))}
                                <FormControl error>
                                    <Select
                                        variant="standard"
                                        labelId="arrange-label"
                                        label="世界に一つのオリジナル"
                                        value="10"
                                        inputProps={{ readOnly: true }}
                                        onClick={handleSelectArrange}
                                    >
                                        <MenuItem value="10">
                                            <Txt size="s">
                                                独自のアレンジを追加
                                            </Txt>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </ControlWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    {SECTIONS.map((section, index) => (
                        <SectionWrapper odd={!!(index % 2)} key={index}>
                            <SectionTitle>
                                <Txt>{section.title}</Txt>
                            </SectionTitle>
                            <SectionBody>
                                <SectionTxt>{section.description}</SectionTxt>
                            </SectionBody>
                            {section.path ? (
                                <SectionLink
                                    to={'/' + section.path + '/'}
                                    odd={!!(index % 2)}
                                >
                                    <SectionImageWrapper>
                                        {section.image}
                                    </SectionImageWrapper>
                                    <IconWrapper>
                                        <ArrowForwardIosIcon
                                            color="secondary"
                                            fontSize="large"
                                        />
                                    </IconWrapper>
                                </SectionLink>
                            ) : (
                                <SectionImageWrapper>
                                    {section.image}
                                </SectionImageWrapper>
                            )}
                        </SectionWrapper>
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <RingBaseSelect marginTop="80px" hideCatchTxt={true} />
                </Grid>
            </Grid>
        </Layout>
    )
}

function convertToPageCtx(data: RingCustomizeQuery): PageContext {
    assert(data.allRingsJson.edges)
    const rings: CustomizeRing[] = data.allRingsJson.edges.map(({ node }) => {
        assert(node.code)
        assert(node.model)
        assert(node.metal)
        assert(node.width)
        assert(node.diamond)
        assert(node.texture)
        assert(node.mill)
        assert(node.images)
        const images = node.images.map((image) => {
            assert(image)
            return image
        })
        return {
            code: node.code,
            model: node.model,
            metal: node.metal,
            width: node.width,
            diamond: node.diamond,
            texture: node.texture,
            mill: node.mill,
            images: images,
        }
    })
    const figure = data.figuresJson
    assert(figure)
    assert(figure.slug)
    assert(figure.name)
    assert(figure.metaTitle)
    assert(figure.metaDescription)

    return {
        rings: rings,
        figure: figure.slug as RingFigure,
        name: figure.name,
        metaTitle: figure.metaTitle,
        metaDescription: figure.metaDescription,
    }
}

export const query = graphql`
    query RingCustomize($figure: String) {
        allRingsJson(filter: { figure: { eq: $figure } }) {
            edges {
                node {
                    code
                    model
                    metal
                    mill
                    texture
                    width
                    diamond
                    images
                }
            }
        }
        figuresJson(slug: { eq: $figure }) {
            slug
            name
            metaTitle
            metaDescription
        }
    }
`

const Title = styled('h1')({
    display: 'flex',
    margin: 0,
    padding: '12px',
})
const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0 20px 20px',
})
const CatchTxt1 = styled(Txt)({
    fontWeight: 'bold',
    marginBottom: '4px',
})
const CatchTxt2 = styled(Txt)({
    color: '#f44336',
})
const RingImageGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        position: 'sticky',
        top: '56px',
        zIndex: 3,
        backgroundColor: '#fafafa',
    },
}))
const RingImageArea = styled('div')({
    height: '348px',
})
const RingImage = styled('img')({
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto',
    display: 'block',
})
const RingInfoWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
})
const Row = styled('div')({
    padding: '5px 0',
})
const LinksWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
})
const Label = styled(Txt)({
    display: 'inline-block',
    fontWeight: 'bold',
    width: '60px',
})
const ControlWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
})
const FormControl = styled(_FormControl)({
    marginBottom: '20px',
})
const HelpLink = styled(Link)({
    padding: '8px',
})
type SectionWrapperProps = {
    odd: boolean
}
const SectionWrapper = styled('div')<SectionWrapperProps>(({ odd }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '48px',
    alignItems: odd ? 'flex-end' : 'flex-start',
}))
const SectionTitle = styled('h2')({
    margin: '0 12px',
    paddingBottom: '4px',
    borderBottom: '1px solid #aaaaaa',
})
const SectionBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
})
const SectionTxt = styled(Txt)({
    marginBottom: '4px',
})
type SectionLinkProps = {
    odd: boolean
}
const SectionLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'odd',
})<SectionLinkProps>(({ odd }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: odd ? 'row-reverse' : 'row',
}))
const IconWrapper = styled('div')({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
})
const SectionImageWrapper = styled('div')({
    width: '80%',
})

export default CustomizePage
