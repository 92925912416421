import { styled } from '@mui/system'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import type { OptionImageQuery } from '../../../types/graphql-types'
import type { Node } from 'gatsby'

type Props = {
    filename: string
}

export const OptionImage: React.FC<Props> = ({ filename }) => {
    const data: OptionImageQuery = useStaticQuery(graphql`
        query OptionImage {
            allFile(filter: { relativePath: { glob: "options/*/*" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                width: 120
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                        relativePath
                    }
                }
            }
        }
    `)
    const node = data.allFile.edges.find((edge) => {
        return edge.node.relativePath === 'options/' + filename
    })?.node

    if (node === undefined) return <></>

    const image = getImage(node as unknown as Node)
    if (image === undefined) return <></>

    return <Img image={image} alt="" />
}

const Img = styled(GatsbyImage)({
    margin: '0 15px 0 5px',
})
